import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/.yarn/__virtual__/lazy-i18n-virtual-6ebbf03ff5/1/packages/lazy-i18n/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/analytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/authentication.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/custom-theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/dark-mode-toggle.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/packages/next/src/features/footer.module.scss");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/packages/next/src/features/body.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/fullstory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/html.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/notebook-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/notifications-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/notifications.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/posthog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/sentry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/session-id-provider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/buildhome/repo/packages/next/src/features/status-bar.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/tracer-provider-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/features/window-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/modules/quisi/link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/modules/quisi/theme.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/buildhome/repo/packages/next/src/modules/react-clarity/index.tsx");
